import { stepActionTypes } from "../actions/stepActions/stepActionTypes"
import { CLEAR_ON_SIGNOUT } from "../constants"

const initialActionState = {
  steps: [],
}

const stepReducer = (state = initialActionState, action) => {
  const { payload } = action
  switch (action.type) {
    case stepActionTypes.SET_STEPS:
      return {
        ...state,
        steps: payload,
      }
    case stepActionTypes.ADD_STEP:
      return {
        ...state,
        steps: [...state.steps, payload],
      }
    case stepActionTypes.REMOVE_STEP:
      return {
        ...state,
        steps: state.steps.filter((action) => action.id !== payload.id),
      }
    case stepActionTypes.UPDATE_STEP:
      return {
        ...state,
        steps: state.steps.map((act) =>
          act.id === payload.id ? { ...act, ...payload } : act
        ),
      }
    case stepActionTypes.CLEAR_STEP:
      return {
        ...state,
        steps: [],
      }
    case CLEAR_ON_SIGNOUT:
      return initialActionState
    default:
      return state
  }
}

export default stepReducer
