import { useEffect } from "react"
import { useDispatch } from "react-redux"
import ChangePasswordAlert from "../components/Alert/ChangePasswordAlert.jsx"
import AlertComponent from "../components/Alert/index.jsx"
import GlobalAlert from "../components/GlobalAlert/index.jsx"
import { fetchAnnouncement } from "../redux/reducers/alertReducer.js"
import Sidebar from "../components/Sidebar2/index.jsx"
import "./style.scss"
import { fetchUserData } from "../redux/actions/userActions.js"

export default function DashboardLayout({ children }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAnnouncement())
    dispatch(fetchUserData())
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <AlertComponent />
      <ChangePasswordAlert />
      <div className="DashboardLayout">
        <Sidebar />
        <div className="content-wrapper bg-background">
          <GlobalAlert />
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    </>
  )
}
