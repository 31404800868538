import { headTailActionTypes } from "../actions/headTailActions/headTailActionsTypes"
import { CLEAR_ON_SIGNOUT } from "../constants"

const initialActionState = {
  headTail: [
    {
      id: "1",
      type: "start", // input node
      data: { label: "Input Node" },
      position: { x: 24, y: 24 },
    },
  ],
}

const headTailReducer = (state = initialActionState, action) => {
  const { payload } = action
  switch (action.type) {
    case headTailActionTypes.SET_HEAD_TAIL:
      return {
        ...state,
        headTail: payload,
      }
    case headTailActionTypes.ADD_TAIL:
      return {
        ...state,
        headTail: [...state.headTail, payload],
      }
    case headTailActionTypes.REMOVE_TAIL:
      return {
        ...state,
        headTail: state.headTail.filter((action) => action.id !== payload.id),
      }
    case headTailActionTypes.UPDATE_HEAD_TAIL:
      return {
        ...state,
        headTail: state.headTail.map((act) =>
          act.id === payload.id ? { ...act, ...payload } : act
        ),
      }
    case headTailActionTypes.CLEAR_HEAD_TAIL:
      return {
        ...state,
        headTail: [],
      }
    case CLEAR_ON_SIGNOUT:
      return initialActionState
    default:
      return state
  }
}

export default headTailReducer
