export default class Observeble {
    #prop = undefined;
    #events = [];
  
    constructor(prop) {
      this.#prop = prop;
    }
  
    dispatch(prop) {
      this.#prop = prop;
      this.#events.forEach((elm) => {
        elm(this.#prop);
      });
    }
  
    subscribe(callback) {
      this.#events.push(callback);
    }
  }
  