// import CanvasCreate from "../views/canvas/Create";

import { lazy } from "react";

const Component = () => (
  <div>This is canvas layout</div>
)

const CanvasCreate = lazy( () => import("../views/canvas/Create"))


export default [
  {
    component: Component,
    path: "/",
    exact: true,
    title: "diagram",
    permission: "diagram",
    children: [],
  },
  {
    component: CanvasCreate,
    path: "/create",
    exact: true,
    title: "canvas",
    permission: "canvas_create",
    children: [],
  },
  {
    component: CanvasCreate,
    path: "/:id/:type_code",
    exact: true,
    title: "canvas",
    permission: "canvas_edit",
    children: [],
  }
].map((route) => ({
  ...route,
  path: `/canvas${route.path}`,
  id: 'canvas-' + Math.random() + new Date().getTime(),
}));