import {
  SET_FILTER_VALUES,
  CLEAR_FILTER_VALUES,
  CLEAR_ON_SIGNOUT,
  // SET_STATE_FIELD_VALUE,
  // CLEAR_FORM_VALUES,
} from "../constants"

const INITIAL_STATE = {
  applicationPage: {
    searchText: "",
    city: null,
    region: null,
    status: null,
    currentPage: null,
  },
  newOffers: {
    searchText: "",
    city: null,
    region: null,
    status: null,
    currentPage: null,
  },
}

export default function applicationFormReducer(
  state = INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case SET_FILTER_VALUES:
      return {
        ...state,
        [payload.page]: {
          ...state[payload.page],
          [payload.name]: payload.value,
        },
      }

    case CLEAR_FILTER_VALUES:
      return INITIAL_STATE

    case CLEAR_ON_SIGNOUT:
      return INITIAL_STATE

    default:
      return state
  }
}

export const setApplicationFilterValue = (name, value, page) => ({
  type: SET_FILTER_VALUES,
  payload: { name, value, page },
})

export const clearFilterValue = () => ({ type: CLEAR_FILTER_VALUES })
