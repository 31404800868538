import { CLEAR_ON_SIGNOUT, MENU_KEY } from "../constants"

const INITIAL_STATE = {
  menu_key: ["1"],
}

export default function basicReducer(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case MENU_KEY:
      return {
        ...state,
        menu_key: payload,
      }
    case CLEAR_ON_SIGNOUT:
      return INITIAL_STATE
    default:
      return state
  }
}
