// import { makeStyles } from "@material-ui/core/styles"
import "./index.scss"
import { CircularProgress } from "@material-ui/core"

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     "& > * + *": {
//       marginTop: theme.spacing(2),
//     },
//   },
// }))

export default function Button({
  className,
  style,
  children,
  icon: Icon,
  color = "primary",
  loading = false,
  shape = "filled",
  borderWidth = 2,
  position = "left",
  size = "medium",
  borderType = "rounded",
  disabled = false,
  hoverTitle = "",
  width,
  ...rest
}) {
  // console.log(children);

  // const bgColors = (color, lavel = 600) => `bg-${color}-${lavel}`
  // const textColors = (color, lavel = 600) => `text-${color}-${lavel}`
  // const borderColors = (color, lavel = 600) => `border-${color}-${lavel}`
  const borderWidths = (width) => `border-${width}`

  const bgColors = {
    blueGray: "bg-blue-gray-600",
    gray: "bg-gray-600",
    brown: "bg-brown-600",
    deepOrange: "bg-deep-orange-600",
    orange: "bg-orange-600",
    amber: "bg-amber-600",
    yellow: "bg-yellow-600",
    lime: "bg-lime-600",
    lightGreen: "bg-light-green-600",
    green: "bg-green-600",
    teal: "bg-teal-600",
    cyan: "bg-cyan-600",
    lightBlue: "bg-light-blue-600",
    blue: "bg-blue-600",
    indigo: "bg-indigo-600",
    deepPurple: "bg-deep-purple-600",
    purple: "bg-purple-600",
    pink: "bg-pink-600",
    red: "bg-red-600",
    primary: "bg-primary-600",
  }

  const textColors = {
    blueGray: "text-blue-gray-600",
    gray: "text-gray-600",
    brown: "text-brown-600",
    deepOrange: "text-deep-orange-600",
    orange: "text-orange-600",
    amber: "text-amber-600",
    yellow: "text-yellow-600",
    lime: "text-lime-600",
    lightGreen: "text-light-green-600",
    green: "text-green-600",
    teal: "text-teal-600",
    cyan: "text-cyan-600",
    lightBlue: "text-light-blue-600",
    blue: "text-blue-600",
    indigo: "text-indigo-600",
    deepPurple: "text-deep-purple-600",
    purple: "text-purple-600",
    pink: "text-pink-600",
    red: "text-red-600",
    primary: "text-primary-600",
  }

  const borderColors = {
    blueGray: "border-blue-gray-600",
    gray: "border-gray-600",
    brown: "border-brown-600",
    deepOrange: "border-deep-orange-600",
    orange: "border-orange-600",
    amber: "border-amber-600",
    yellow: "border-yellow-600",
    lime: "border-lime-600",
    lightGreen: "border-light-green-600",
    green: "border-green-600",
    teal: "border-teal-600",
    cyan: "border-cyan-600",
    lightBlue: "border-light-blue-600",
    blue: "border-blue-600",
    indigo: "border-indigo-600",
    deepPurple: "border-deep-purple-600",
    purple: "border-purple-600",
    pink: "border-pink-600",
    red: "border-red-600",
    primary: "border-primary-600",
  }

  const getSize = (key) => {
    switch (key) {
      case "small":
        return {
          size: "h-6 px-2 py-0",
          fontSize: "text-sm",
          radius: "rounded",
        }
      case "medium":
        return {
          size: "h-8 px-3 py-1 min:w-7",
          fontSize: "text-sm",
          radius: "rounded-md",
        }
      case "large":
        return {
          size: "px-4 py-2",
          fontSize: "text-sm",
          radius: "rounded-md",
        }

      default:
        break
    }
  }

  const getShape = (key) => {
    // console.log(key);
    switch (key) {
      case "filled":
        return {
          color: `${bgColors[color]} iconColor-filled hover:opacity-90 ${borderWidths(borderWidth)}} ${
            borderColors[color]
          }`,
          background: "",
        }
      case "outlined":
        return {
          color: `bg-transparent ${textColors[color]} ${borderWidths(borderWidth)}} ${
            borderColors[color]
          } hover:bg-background_2`,
        }
      case "text":
        return {
          color: `bg-transparent ${textColors[color]} hover:opacity-90 ${borderWidths(borderWidth)}} border-white`,
        }

      default:
        return {
          color: `${bgColors[color]} iconColor-filled hover:opacity-90 ${borderWidths(borderWidth)}} ${
            borderColors[color]
          }`,
          background: "",
        }
    }
  }

  return (
    <div>
      <button
        title={hoverTitle}
        disabled={disabled || loading}
        type="button"
        style={style}
        className={`
          button
          ${className}
          focus:outline-none
          transition
          ${children ? "" : "w-9 h-9"}
          focus:ring focus:border-blue-300 
          focus-within:z-40
          ${getShape(shape).color}
          ${getSize(size).size}
          ${borderType === "rectangle" ? "rounded-none" : getSize(size).radius}
          ${disabled || loading ? "disabled" : ""}
          text-white
        `}
        {...rest}
      >
        <div className={`flex items-center justify-center ${children ? "space-x-2" : ""} font-medium`}>
          {loading && <CircularProgress size={16} color="primary" />}
          {Icon && position === "left" && <Icon style={{ fontSize: "18px" }} />}
          <div className={`whitespace-nowrap	${getSize(size).fontSize}`}>{children}</div>
          {Icon && position === "right" && <Icon style={{ fontSize: "18px" }} />}
        </div>
      </button>
    </div>
  )
}
