import { parseCookies } from "nookies"

const handleLoginRedirect = (callback) => {
  const { access_token } = parseCookies()
  if (!access_token) {
    const backUrl = {
      development: process.env.REACT_APP_FULL_DOMAIN,
      production: process.env.REACT_APP_FULL_DOMAIN,
    }[process.env.NODE_ENV]
    const redirectUrl = `${
      process.env.REACT_APP_ONE_ID_BASE ||
      "https://sso.egov.uz/sso/oauth/Authorization.do"
    }?response_type=one_code&client_id=yerelektron&redirect_uri=${backUrl}&Scope=yerelektron&state=IDPW`
    window.location.replace(redirectUrl)
  } else if (callback) {
    callback()
  }
}
export default handleLoginRedirect
