import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { VerifiedUserOutlined } from "@material-ui/icons"
import { Avatar } from "@material-ui/core"

function SidebarProfile({ userData, rightSideVisible }) {
  const history = useHistory()
  const baseUrl = process.env.REACT_APP_CDN
  const backHome = () => history.push("/home/profile")
  if (!userData) return null
  return (
    <div className="layout__profile" onClick={backHome}>
      {!rightSideVisible ? (
        <Avatar size={40} className="mt-2" src={`${baseUrl}/${userData?.photo}`} icon={<VerifiedUserOutlined />} />
      ) : (
        <div className="profile__avatar mt-1">
          <div className="flex mb-2  gap-1  justify-start items-center w-full">
            <Avatar size={70} src={`${baseUrl}/${userData?.photo}`} gap={1} icon={<VerifiedUserOutlined />} />
            <div className="profile__user">
              {`${userData?.last_name} ${userData?.first_name} ${userData?.middle_name}`} <br /> (
              {userData?.role && userData?.role?.name})
            </div>
          </div>
          <div className="profile__organization">{userData?.organization?.full_name}</div>
          <div className="profile__area">
            {userData?.city && userData?.city?.name}
            {userData?.city && userData?.region && <br />}
            {userData?.city && userData?.region && userData?.region?.name}
          </div>
        </div>
      )}
    </div>
  )
}

export default SidebarProfile
