export const SET_AUTH_CREDENTIALS = `SET_AUTH_CREDENTIALS`
export const SET_AUTH_TOKENS = `SET_AUTH_TOKENS`
export const REFRESH_ACCESS_TOKEN = `REFRESH_ACCESS_TOKEN`

export const CLEAR_ON_SIGNOUT = `CLEAR_ON_SIGNOUT`
export const CHANGE_LANGUAGE = `CHANGE_LANGUAGE`

export const IS_LOADING = "IS_LOADING"
export const MENU_KEY = "MENU_KEY"

export const ADD_NEW_ALERT = "ADD_NEW_ALERT"
export const DELETE_ALERT = "DELETE_ALERT"
export const SET_GLOBAL_ALERT = "SET_GLOBAL_ALERT"
export const SET_GLOBAL_ALERT_HEIGHT = "SET_GLOBAL_ALERT_HEIGHT"

export const SET_USER_VERIFIED = "SET_USER_VERIFIED"
export const SET_USER_DATA = "SET_USER_DATA"
export const SET_FORM_VALUES = "SET_FORM_VALUES"
export const SET_STATE_FIELD_VALUE = "SET_STATE_FIELD_VALUE"
export const CLEAR_FORM_VALUES = "CLEAR_FORM_VALUES"

export const SET_FILTER_VALUES = "SET_FILTER_VALUES"
export const CLEAR_FILTER_VALUES = "CLEAR_FILTER_VALUES"
export const File_NAME_ID = "FILE_NAME_ID"

export const AREA_PAGINATION = "AREA_PAGINATION"
export const AREA_SELECTEDTAB = "AREA_SELECTEDTAB"
export const AREA_ALL_VALUES = "AREA_ALL_VALUES"

export const STATUS_PAGINATION = "STATUS_PAGINATION"
export const STATUS_SELECTEDTAB = "STATUS_SELECTEDTAB"
export const STATUS_ALL_VALUES = "STATUS_ALL_VALUES"
