import logo from "../assets/icons/logo_blue.png"
import { yerelektron } from "../assets/icons/icons"

const defaultSetting = {
 
  baseURL: process.env.REACT_APP_BASE_URL, // base url for whole project
 
  mode: "default", // mode => default | light | semi-dark
  avatar: "",
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky sidebar
  project: {
    title: "YERELEKTRON",
    logo: logo,
    title_svg: yerelektron,
    createdBy: "Geoinfocom DUK",
  },
  colors: {
    primary: "rgba(64, 148, 247, 1)",
    background: "rgba(220, 233, 249, 1)",
    secondary: "rgba(110, 139, 183, 1)",
    background_2: "rgba(229, 233, 235, 1)",
    black: "rgba(48, 57, 64, 1)",
    grey: "rgba(132, 145, 154, 1)",
    white: "rgba(255, 255, 255, 1)",
    success: "rgba(56, 217, 185, 1)",
    purple: "rgba(196, 121, 243, 1)",
    yellow: "rgba(248, 221, 78, 1)",
    error: "rgba(247, 102, 89, 1)",
  },
  elementsPerPage: 10,
}

document.title = defaultSetting.project.title

export default defaultSetting

export const inputTypes = [
  { value: "radio", label: "one.of.the.list" },
  { value: "checkbox", label: "several.from.the.list" },
  { value: "photo", label: "photo" },
  { value: "boolean", label: "yes.no" },
  { value: "string", label: "text.field" },
  { value: "textarea", label: "textarea" },
  { value: "number", label: "numeric.field" },
  { value: "date", label: "date" },
  { value: "file", label: "file" },
  { value: "collection", label: "list.with.dynamic.options" },
  // { value: "map", label: "map" },
]

export const actionTypes = {
  ACCEPT: { color: "green" },
  ASSIGN: { color: "#4094f7" },
  APPROVE: { color: "green" },
  REJECT: { color: "red" },
  EDIT: { color: "#4094f7" },
  REVERT: { color: "red" },
  SET_AS_READY: { color: "#4094f7" },
  SEND_TO_AUCTION: { color: "#4094f7" },
}

export const createValidatorByType = (type, yup, validation) => {
  if (type === "boolean") {
    return yup.bool(validation)
  } else if (type === "checkbox" || type === "map") {
    return yup.object(validation)
  }
  return yup.string(validation)
}

export const getObjectInputTypes = (t) => {
  return {
    radio: t("one.of.the.list"),
    checkbox: t("several.from.the.list"),
    boolean: t("yes.no"),
    string: t("text.field"),
    textarea: t("textarea"),
    number: t("numeric.field"),
    date: t("date"),
    file: t("file"),
    map: t("map"),
    photo: t("photo"),
  }
}

export const getInputTypes = (t) => {
  return inputTypes.map((val) => ({ ...val, label: t(val.label) }))
}

export const newEntityStatusId = "6103bb05dd7aa74db9a779a6"
export const newEntityStatusId2 = "61d7f0d0dc25f43ce91f3c8d"
export const newEntityStatusId3 = "61fa5d7ea9a0964dbfcdd58d"
export const newEntityStatusId4 = "63a1667b6bc23e60bc6794c0"
export const mapPropertyId = "6113589b73bf6fe15aaef566"
export const mapDraftPropertyId = "6113589b73bf6fe15aaef566"
export const mapTypePropertyId = "6113590973bf6fe15aaef567"
export const rejectPropertyIdFile = "629264f6d4fcb1bb281580a4"
export const areaPropertyId = "61c4053d8379818d937fe91b"
export const statusPropertyId = "61fa6bc59cc8808579eb9920"
export const rejectPropertyIdDescription = "629266c5d4fcb1bb281580d1"
export const mapboxToken =
  process.env.MAPBOX_TOKEN ||
  "pk.eyJ1IjoiYWJkdWxsb2htdWhhbW1hZCIsImEiOiJja2V5NWQ4bjkwa3JsMnRsOGw4djBndDByIn0.Yca-pHsZpv8QUMVQqg8gTQ"

export const newEntityValue1 = "6142c2076074f7fa21292a3b"
export const newEntityValue2 = "6142c2076074f7fa21292a3c"
export const newEntityValue3 = "6142c2146074f7fa21292a87"
export const newEntityValue4 = "6142c2086074f7fa21292a3e"
export const newEntityValue5 = "6142c2086074f7fa21292a3d"
export const newEntityValue6 = "6242e5ce748e61558f1de6b5"
export const newEntityValue7 = "624d8082ed61d1fa13ae2221"

//regions

export const allEntityValues = [
  newEntityValue1,
  newEntityValue2,
  newEntityValue3,
  newEntityValue4,
  newEntityValue5,
  newEntityValue6,
  newEntityValue7,
]

export const newEntityPropertyId = "619e34ddfab0709cce175ba4"
export const regionOlmaliqId = "611380893b56884e1ee48d56"
// export const regionSamarkandId = "6113805491d80c04dc0fa91f"
export const auctionPropertyId = "6183b256b89100dd3a346376" //auksion turi
export const usersINNAndPINFLPropertyId = "6253f88242dfa875da4769aa"
export const huquqturiNarxPropertyId = "637de57baf1e0e58a4e9dbb8"

export const importantInfosPropId = "6252f6b742dfa875da471159"
export const constructionObjPropId = "619e34ddfab0709cce175ba4"
export const constructorFieldId1 = "6242e5ce748e61558f1de6b5"

export const cadastrNumber1 = "60f14f091cf5362a1aac9781"
export const cadastrNumber2 = "61fa95518051a195f73063aa"
export const cadastrNumber3 = "621361a9142ef90459e1e0ac"
export const cadastrNumber4 = "64b8d1338ae8839321941324"

export const typeOfLawId = "61fa6d549cc8808579eb9924"

export const superadminRoleId = "61134a4473bf6fe15aaef55c"
export const kadastrPalatasiTumanRoleId = "6136faefc55c3d063b28a11f"
export const organizationDavlatkadastirPalatasi = "61027e35772d476a220673e6"
export const maqsadiPropertyNewEntity = "63847cb9af44a5e1958f32c6"
