import { HashRouter } from "react-router-dom"
import AppRouter from "./routes/index.jsx"
import "./App.scss"

export default function App() {
  return (
    <HashRouter>
      <AppRouter />
    </HashRouter>
  )
}
