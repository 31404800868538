import { parallelActionTypes } from "../actions/parallelActions/parallelActionTypes"
import { CLEAR_ON_SIGNOUT } from "../constants"

const initialActionState = {
  parallelGateways: [],
}

const parallelReducer = (state = initialActionState, action) => {
  const { payload } = action
  switch (action.type) {
    case parallelActionTypes.SET:
      return {
        ...state,
        parallelGateways: payload,
      }
    case parallelActionTypes.ADD:
      return {
        ...state,
        parallelGateways: [...state.parallelGateways, payload],
      }
    case parallelActionTypes.REMOVE:
      return {
        ...state,
        parallelGateways: state.parallelGateways.filter(
          (action) => action.id !== payload.id
        ),
      }
    case parallelActionTypes.UPDATE:
      return {
        ...state,
        parallelGateways: state.parallelGateways.map((act) =>
          act.id === payload.id ? { ...act, ...payload } : act
        ),
      }
    case parallelActionTypes.CLEAR:
      return {
        ...state,
        parallelGateways: [],
      }
    case CLEAR_ON_SIGNOUT:
      return initialActionState
    default:
      return state
  }
}

export default parallelReducer
