export const brandLogo = (
  <svg
    width="36"
    height="28"
    viewBox="0 0 36 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.47567 6.03918L0.818841 7.71854C0.314451 7.91231 0.0622559 8.42904 0.0622559 8.94576C0.0622559 9.46249 0.377499 9.97921 0.818841 10.173L3.52994 11.4002L9.83481 8.49363L4.47567 6.03918Z"
      fill="#4E7BAB"
    />
    <path
      d="M12.3575 9.78577L6.11566 12.6278L9.3942 14.1133L15.5099 11.2068L12.3575 9.78577Z"
      fill="#4E7BAB"
    />
    <path
      d="M18.032 12.4984L11.9793 15.3404L17.4645 17.8594C17.6537 17.924 17.8428 17.9886 18.032 17.9886C18.2211 17.9886 18.4103 17.924 18.5994 17.8594L24.1477 15.3404L18.032 12.4984Z"
      fill="#4E7BAB"
    />
    <path
      d="M15.4469 5.97483L12.3575 7.39582L6.99835 4.87679L10.0247 3.52039L15.4469 5.97483Z"
      fill="#4E7BAB"
    />
    <path
      d="M21.1211 8.62302L18.0317 10.1086L14.9423 8.62302L18.0317 7.20203L21.1211 8.62302Z"
      fill="#4E7BAB"
    />
    <path
      d="M23.6434 9.78577L20.491 11.2713L26.5436 14.1133L29.8222 12.6278L23.6434 9.78577Z"
      fill="#4E7BAB"
    />
    <path
      d="M23.391 2.35768L17.9688 4.81212L12.6096 2.29309L17.4644 0.097008C17.7796 -0.0321732 18.221 -0.0321732 18.5362 0.097008L23.391 2.35768Z"
      fill="#4E7BAB"
    />
    <path
      d="M29.0025 4.87679L23.6434 7.39582L20.6171 5.97483L26.0393 3.52039L29.0025 4.87679Z"
      fill="#4E7BAB"
    />
    <path
      d="M35.9377 8.94576C35.9377 9.46249 35.6225 9.97921 35.1811 10.173L32.47 11.4002L26.2282 8.55822L31.5874 6.03918L35.2442 7.71854C35.6225 7.9769 35.9377 8.42904 35.9377 8.94576Z"
      fill="#4E7BAB"
    />
    <path
      d="M35.1812 12.6923L34.8029 12.4985L18.5363 19.991C18.3472 20.0556 18.158 20.1202 17.9689 20.1202C17.7797 20.1202 17.5906 20.0556 17.4015 19.991L1.19793 12.5631L0.756585 12.7569C0.315244 12.9507 0 13.4674 0 13.9841C0 14.5008 0.315244 15.0176 0.756585 15.2113L17.4645 22.833C17.6537 22.8976 17.8428 22.9622 18.0319 22.9622C18.2211 22.9622 18.4102 22.8976 18.5994 22.833L35.1812 15.2113C35.6225 15.0176 35.9378 14.5008 35.9378 13.9841C35.9378 13.4028 35.6225 12.9507 35.1812 12.6923Z"
      fill="#4E7BAB"
    />
    <path
      d="M35.1804 17.7946L34.7391 17.6008L18.5355 25.0288C18.3464 25.0934 18.1572 25.1579 17.9681 25.1579C17.779 25.1579 17.5898 25.0934 17.4007 25.0288L1.26018 17.5363L0.818841 17.7946C0.3775 17.9884 0.0622559 18.5051 0.0622559 19.0218C0.0622559 19.5386 0.3775 20.0553 0.818841 20.2491L17.4637 27.8707C17.6529 27.9353 17.842 27.9999 18.0312 27.9999C18.2203 27.9999 18.4094 27.9353 18.5986 27.8707L35.2435 20.1845C35.6848 19.9907 36 19.474 36 18.9572C36 18.4405 35.6218 17.9884 35.1804 17.7946Z"
      fill="#4E7BAB"
    />
  </svg>
)

export const yerelektron = (
  <svg
    width="155"
    height="13"
    viewBox="0 0 155 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.233643 0.786865L4.54134 8.21117V12.6287H6.68074V8.21117L10.9884 0.786865H8.56564L5.66884 6.02547H5.55324L2.65634 0.786865H0.233643Z"
      fill="#6B6966"
    />
    <path
      d="M12.4099 12.6287H20.158V10.8305H14.5551V7.59827H19.7128V5.79997H14.5551V2.58507H20.1117V0.786865H12.4099V12.6287Z"
      fill="#6B6966"
    />
    <path
      d="M22.2026 12.6287H24.3478V8.29207H26.5334L28.8578 12.6287H31.2516L28.6901 7.93357C30.0836 7.37277 30.818 6.18157 30.818 4.57417C30.818 2.31337 29.3609 0.786865 26.6433 0.786865H22.2026V12.6287ZM24.3478 6.51117V2.57937H26.3137C27.9153 2.57937 28.6323 3.31367 28.6323 4.57417C28.6323 5.83467 27.9153 6.51117 26.3252 6.51117H24.3478Z"
      fill="#6B6966"
    />
    <path
      d="M32.6949 12.6287H40.4429V10.8305H34.8401V7.59827H39.9977V5.79997H34.8401V2.58507H40.3967V0.786865H32.6949V12.6287Z"
      fill="#6B6966"
    />
    <path
      d="M42.4875 12.6287H49.8482V10.8305H44.6327V0.786865H42.4875V12.6287Z"
      fill="#6B6966"
    />
    <path
      d="M51.6124 12.6287H59.36V10.8305H53.758V7.59827H58.915V5.79997H53.758V2.58507H59.314V0.786865H51.6124V12.6287Z"
      fill="#6B6966"
    />
    <path
      d="M61.405 12.6287H63.55V9.10157L64.863 7.55197L68.396 12.6287H70.974L66.355 6.10067L70.934 0.786865H68.315L63.695 6.22787H63.55V0.786865H61.405V12.6287Z"
      fill="#6B6966"
    />
    <path
      d="M71.806 2.58507H75.466V12.6287H77.594V2.58507H81.254V0.786865H71.806V2.58507Z"
      fill="#6B6966"
    />
    <path
      d="M82.978 12.6287H85.123V8.29207H87.309L89.633 12.6287H92.027L89.465 7.93357C90.859 7.37277 91.593 6.18157 91.593 4.57417C91.593 2.31337 90.136 0.786865 87.419 0.786865H82.978V12.6287ZM85.123 6.51117V2.57937H87.089C88.691 2.57937 89.408 3.31367 89.408 4.57417C89.408 5.83467 88.691 6.51117 87.101 6.51117H85.123Z"
      fill="#6B6966"
    />
    <path
      d="M104.052 6.7078C104.052 2.88 101.716 0.625 98.611 0.625C95.5 0.625 93.17 2.88 93.17 6.7078C93.17 10.5298 95.5 12.7906 98.611 12.7906C101.716 12.7906 104.052 10.5356 104.052 6.7078ZM101.895 6.7078C101.895 9.4023 100.542 10.8652 98.611 10.8652C96.685 10.8652 95.326 9.4023 95.326 6.7078C95.326 4.0133 96.685 2.5505 98.611 2.5505C100.542 2.5505 101.895 4.0133 101.895 6.7078Z"
      fill="#6B6966"
    />
    <path
      d="M115.713 0.786865H113.58V8.85877H113.476L107.902 0.786865H105.982V12.6287H108.127V4.56257H108.225L113.805 12.6287H115.713V0.786865Z"
      fill="#6B6966"
    />
    <path
      d="M126.503 12.6287L127.48 9.7087H131.932L132.915 12.6287H135.205L131.03 0.786865H128.382L124.213 12.6287H126.503ZM128.058 7.9856L129.66 3.21539H129.753L131.354 7.9856H128.058Z"
      fill="#6B6966"
    />
    <path
      d="M134.716 2.5851H138.376V12.6287H140.504V2.5851H144.164V0.786865H134.716V2.5851Z"
      fill="#6B6966"
    />
    <path
      d="M145.367 2.5851H149.027V12.6287H151.155V2.5851H154.815V0.786865H145.367V2.5851Z"
      fill="#6B6966"
    />
  </svg>
)
export const IncomeIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99996 0.666748C4.39996 0.666748 0.666626 4.40008 0.666626 9.00008C0.666626 13.6001 4.39996 17.3334 8.99996 17.3334C13.6 17.3334 17.3333 13.6001 17.3333 9.00008C17.3333 4.40008 13.6 0.666748 8.99996 0.666748ZM4.41663 6.50008L7.33329 3.58341L10.25 6.50008H8.16663V9.83342H6.49996V6.50008H4.41663ZM13.5833 11.5001L10.6666 14.4167L7.74996 11.5001H9.83329V8.16675H11.5V11.5001H13.5833Z"
      fill="#6E7C87"
    />
  </svg>
)
export const ContentCopy = () => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66671 0.666748H1.66671C0.933374 0.666748 0.333374 1.26675 0.333374 2.00008V11.3334H1.66671V2.00008H9.66671V0.666748ZM11.6667 3.33341H4.33337C3.60004 3.33341 3.00004 3.93341 3.00004 4.66675V14.0001C3.00004 14.7334 3.60004 15.3334 4.33337 15.3334H11.6667C12.4 15.3334 13 14.7334 13 14.0001V4.66675C13 3.93341 12.4 3.33341 11.6667 3.33341ZM11.6667 14.0001H4.33337V4.66675H11.6667V14.0001Z"
      fill="#6E7C87"
    />
  </svg>
)

export const ArrowForward = () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.24375 8.57292L3.47708 5.33958L0.24375 2.10625C-0.08125 1.78125 -0.08125 1.25625 0.24375 0.931251C0.56875 0.606251 1.09375 0.606251 1.41875 0.931251L5.24375 4.75625C5.56875 5.08125 5.56875 5.60625 5.24375 5.93125L1.41875 9.75625C1.09375 10.0812 0.56875 10.0812 0.24375 9.75625C-0.0729167 9.43125 -0.08125 8.89792 0.24375 8.57292Z"
      fill="#84919A"
    />
  </svg>
)

export const TypesOfEntity1 = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="12" fill="#FF830B" fillOpacity="0.15" />
    <path
      d="M22.1667 25.6667H18.6667V33.8334H22.1667V25.6667Z"
      fill="#FF830B"
    />
    <path d="M29.75 25.6667H26.25V33.8334H29.75V25.6667Z" fill="#FF830B" />
    <path
      d="M39.6667 36.1667H16.3333V39.6667H39.6667V36.1667Z"
      fill="#FF830B"
    />
    <path
      d="M37.3333 25.6667H33.8333V33.8334H37.3333V25.6667Z"
      fill="#FF830B"
    />
    <path
      d="M28 15.1667L16.3333 21.0001V23.3334H39.6667V21.0001L28 15.1667Z"
      fill="#FF830B"
    />
  </svg>
)
export const TypesOfEntity2 = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="12" fill="#8086FF" fillOpacity="0.15" />
    <path
      d="M37.3333 20.9999H32.6667V18.6666C32.6667 17.3716 31.6283 16.3333 30.3333 16.3333H25.6667C24.3717 16.3333 23.3333 17.3716 23.3333 18.6666V20.9999H18.6667C17.3717 20.9999 16.345 22.0383 16.345 23.3333L16.3333 36.1666C16.3333 37.4616 17.3717 38.4999 18.6667 38.4999H37.3333C38.6283 38.4999 39.6667 37.4616 39.6667 36.1666V23.3333C39.6667 22.0383 38.6283 20.9999 37.3333 20.9999ZM30.3333 20.9999H25.6667V18.6666H30.3333V20.9999Z"
      fill="#8086FF"
    />
  </svg>
)
export const TypesOfEntity3 = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="12" fill="#C479F3" fillOpacity="0.15" />
    <path
      d="M36.1667 17.5H19.8333C18.55 17.5 17.5 18.55 17.5 19.8333V36.1667C17.5 37.45 18.55 38.5 19.8333 38.5H36.1667C37.45 38.5 38.5 37.45 38.5 36.1667V19.8333C38.5 18.55 37.45 17.5 36.1667 17.5ZM24.5 33.8333H22.1667V28H24.5V33.8333ZM29.1667 33.8333H26.8333V30.3333H29.1667V33.8333ZM29.1667 28H26.8333V25.6667H29.1667V28ZM33.8333 33.8333H31.5V22.1667H33.8333V33.8333Z"
      fill="#A23FEE"
    />
  </svg>
)
export const TypesOfEntity4 = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="12" fill="#F8DD4E" fillOpacity="0.2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.4483 29.3184C35.0466 30.4034 36.1666 31.8734 36.1666 33.8334V37.3334H40.8333V33.8334C40.8333 31.29 36.6683 29.785 33.4483 29.3184Z"
      fill="#D29404"
    />
    <path
      d="M24.5 28.0001C27.0773 28.0001 29.1666 25.9107 29.1666 23.3334C29.1666 20.7561 27.0773 18.6667 24.5 18.6667C21.9227 18.6667 19.8333 20.7561 19.8333 23.3334C19.8333 25.9107 21.9227 28.0001 24.5 28.0001Z"
      fill="#D29404"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.5 28.0001C34.0783 28.0001 36.1666 25.9117 36.1666 23.3334C36.1666 20.7551 34.0783 18.6667 31.5 18.6667C30.9516 18.6667 30.4383 18.7834 29.9483 18.9467C30.9166 20.1484 31.5 21.6767 31.5 23.3334C31.5 24.9901 30.9166 26.5184 29.9483 27.7201C30.4383 27.8834 30.9516 28.0001 31.5 28.0001Z"
      fill="#D29404"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.5 29.1667C21.385 29.1667 15.1667 30.7301 15.1667 33.8334V37.3334H33.8333V33.8334C33.8333 30.7301 27.615 29.1667 24.5 29.1667Z"
      fill="#D29404"
    />
  </svg>
)
export const XIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="6" fill="#FFEFEB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.293 14.2936C14.4805 14.1061 14.7348 14.0008 15 14.0008C15.2652 14.0008 15.5195 14.1061 15.707 14.2936L20 18.5866L24.293 14.2936C24.3852 14.1981 24.4956 14.1219 24.6176 14.0695C24.7396 14.0171 24.8708 13.9895 25.0036 13.9883C25.1364 13.9872 25.2681 14.0125 25.391 14.0627C25.5139 14.113 25.6255 14.1873 25.7194 14.2812C25.8133 14.3751 25.8875 14.4867 25.9378 14.6096C25.9881 14.7325 26.0134 14.8642 26.0123 14.997C26.0111 15.1298 25.9835 15.261 25.9311 15.383C25.8787 15.505 25.8025 15.6153 25.707 15.7076L21.414 20.0006L25.707 24.2936C25.8892 24.4822 25.99 24.7348 25.9877 24.997C25.9854 25.2592 25.8802 25.51 25.6948 25.6954C25.5094 25.8808 25.2586 25.986 24.9964 25.9882C24.7342 25.9905 24.4816 25.8897 24.293 25.7076L20 21.4146L15.707 25.7076C15.5184 25.8897 15.2658 25.9905 15.0036 25.9882C14.7414 25.986 14.4906 25.8808 14.3052 25.6954C14.1198 25.51 14.0146 25.2592 14.0123 24.997C14.01 24.7348 14.1108 24.4822 14.293 24.2936L18.586 20.0006L14.293 15.7076C14.1055 15.52 14.0002 15.2657 14.0002 15.0006C14.0002 14.7354 14.1055 14.4811 14.293 14.2936Z"
      fill="#F76659"
    />
  </svg>
)
export const DocumentIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4312:25693)">
      <path
        d="M17.2266 5.63379V19.1952C17.2266 19.6398 16.8672 20 16.4236 20H3.57641C3.13277 20 2.77344 19.6398 2.77344 19.1952V0.804844C2.77344 0.360156 3.13277 0 3.57641 0H11.6059L17.2266 5.63379Z"
        fill="#2196F3"
      />
      <path
        d="M16.0223 4.42578V19.1944C16.0223 19.639 15.6629 19.9992 15.2193 19.9992H16.4237C16.8673 19.9992 17.2267 19.6391 17.2267 19.1944V5.63301L16.0223 4.42578Z"
        fill="#1E88E5"
      />
      <path
        d="M17.2267 5.63379H12.409C11.9655 5.63379 11.606 5.27344 11.606 4.82895V0L17.2267 5.63379Z"
        fill="#90CAF9"
      />
      <path
        d="M13.9236 9.75805H6.07647C5.74311 9.75805 5.47284 9.48781 5.47284 9.15441C5.47284 8.82105 5.74307 8.55078 6.07647 8.55078H13.9235C14.2569 8.55078 14.5272 8.82102 14.5272 9.15441C14.5272 9.48777 14.2569 9.75805 13.9236 9.75805Z"
        fill="#FAFAFA"
      />
      <path
        d="M13.9236 12.1721H6.07647C5.74311 12.1721 5.47284 11.9019 5.47284 11.5685C5.47284 11.2351 5.74307 10.9648 6.07647 10.9648H13.9235C14.2569 10.9648 14.5272 11.2351 14.5272 11.5685C14.5272 11.9018 14.2569 12.1721 13.9236 12.1721Z"
        fill="#FAFAFA"
      />
      <path
        d="M13.9236 14.5862H6.07647C5.74311 14.5862 5.47284 14.3159 5.47284 13.9825C5.47284 13.6492 5.74307 13.3789 6.07647 13.3789H13.9235C14.2569 13.3789 14.5272 13.6491 14.5272 13.9825C14.5272 14.3159 14.2569 14.5862 13.9236 14.5862Z"
        fill="#FAFAFA"
      />
      <path
        d="M10.5433 17.0022H6.07647C5.74311 17.0022 5.47284 16.732 5.47284 16.3986C5.47284 16.0652 5.74307 15.7949 6.07647 15.7949H10.5433C10.8766 15.7949 11.1469 16.0652 11.1469 16.3986C11.1469 16.732 10.8767 17.0022 10.5433 17.0022Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_4312:25693">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
