import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import authRoutes from "./authRoutes"
import dashboardRoutes from "./dashboard-routes"
import canvasRoutes from "./canvas-routes"
import fallbackRoutes from "./fallback-routes"
import CanvasLayout from "../layouts/CanvasLayout"
import FallbackLayout from "../layouts/FallbackLayout"
import DashboardLayout from "../layouts/DashboardLayout"
import { useSelector } from "react-redux"
import { animated, useTransition } from "react-spring"
import { Suspense, useMemo } from "react"
import FullScreenLoader from "../components/FullScreenLoader"

const layouts = [
  {
    component: DashboardLayout,
    path: "/home",
    routes: dashboardRoutes,
    private: true,
  },
  {
    component: CanvasLayout,
    path: "/canvas",
    routes: canvasRoutes,
    private: true,
  },
  {
    component: FallbackLayout,
    path: "/extra",
    routes: fallbackRoutes,
    private: false,
  },
]

const AppRouter = () => {
  const token = useSelector((state) => state.auth.accessToken)

  const permissions = useSelector((state) => state.auth.permissions)
  const location = useLocation()
  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const computedLayouts = useMemo(() => {
    return layouts.map((layout) => ({
      ...layout,
      routes: layout.routes.map((route) => ({
        ...route,
        hasAccess: [...permissions, "dashboard"].includes(route.permission),
      })),
    }))
  }, [permissions])

  if (!token)
    return (
      <Switch>
        {authRoutes.map((route) => (
          <Route
            path={route.path}
            exact={route.exact}
            key={route.id}
            render={(routeProps) => (
              <route.layout history={routeProps.history}>
                <route.component {...routeProps} />
              </route.layout>
            )}
          />
        ))}
        <Redirect to="/auth/login" />
      </Switch>
    )

  return (
    <Switch>
      {computedLayouts.map((layout, index) => (
        <Route
          key={index}
          path={layout.path}
          render={(routeProps) => (
            <layout.component>
              {layout.path !== "/canvas" ? (
                transitions((props, item) => (
                  <animated.div style={props}>
                    <div style={{ position: "absolute", width: "100%" }}>
                      <Switch location={item}>
                        {layout.routes.map((route) => (
                          <Route
                            key={route.id}
                            path={route.path}
                            render={(routeProps) => {
                              return !route.hasAccess ? (
                                <noAccessComponent />
                              ) : (
                                <Suspense fallback={<FullScreenLoader />}>
                                  <route.component {...routeProps} />
                                </Suspense>
                              )
                            }}
                            exact
                          />
                        ))}
                      </Switch>
                      {/* <div className="flex z-50 justify-center bg-white my-2 py-1" style={{ width: "100%" }}>
                        <p className="opacity-85">
                          Barcha huquqlar himoyalangan. Axborot tizimi
                          <a href="https://geoinfocom.uz/" rel="noreferrer" target="_blank" className="px-1">
                            <b>“Geoinfocom” DM</b>
                          </a>
                          tomonidan ishlab chiqilgan.
                        </p>
                      </div> */}
                    </div>
                  </animated.div>
                ))
              ) : (
                <Switch>
                  {layout.routes.map((route) => (
                    <Route
                      key={route.id}
                      path={route.path}
                      render={(routeProps) => {
                        return !route.hasAccess ? (
                          <noAccessComponent />
                        ) : (
                          <Suspense fallback={<FullScreenLoader />}>
                            <route.component {...routeProps} />
                          </Suspense>
                        )
                      }}
                      exact
                    />
                  ))}
                </Switch>
              )}
            </layout.component>
          )}
        ></Route>
      ))}
      <Redirect from="/" to="/home/dashboard" />
      <Redirect from="*" to="/extra/fallback-404" />
    </Switch>
  )
}

export default AppRouter
