import "./style.scss"

import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import LockIcon from "@material-ui/icons/Lock"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Visibility from "@material-ui/icons/Visibility"
import TelegramIcon from "@material-ui/icons/Telegram"
import Button from "../../components/Button"
import AlertComponent from "../../components/Alert"
import KadastrLogo from "../../assets/icons/kadastr-logo.svg"
import axios from "../../utils/axios"
import { SET_AUTH_TOKENS } from "../../redux/constants"
import { showAlert } from "../../redux/reducers/alertReducer"
import handleLoginRedirect from "../../utils/handleLoginRedirect"

const KEY_CODE = "#"
const SEQ_LOGIN = "002"

export default function Login() {
  const { t } = useTranslation()
  const history = useHistory()
  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const [url, setUrl] = useState(null)
  const [passworType, setPassordType] = useState(true)
  const [codeInputVisible, setCodeInputVisible] = useState(false)
  const [loginVisible, setLoginVisible] = useState(false)

  useEffect(() => {
    function codeInputVisibilityHandler({ key }) {
      if (key === KEY_CODE) {
        setCodeInputVisible((old) => !old)
        if (loginVisible) {
          setLoginVisible(false)
        }
      }
    }

    window.addEventListener("keydown", codeInputVisibilityHandler)
    return () => {
      window.removeEventListener("keydown", codeInputVisibilityHandler)
    }
  }, [loginVisible])

  useEffect(() => {
    setUrl(window.location.search.slice(6, 42))
    //eslint-disable-next-line
  }, [window.location.search])

  useEffect(() => {
    if (!url) return

    axios
      .get(`/staff/one-id/${url}`)
      .then((res) => {
        console.log("res", res)
        const permissions = res.role.permissions.map((permission) => permission.name)
        dispatch({
          type: "SET_AUTH_TOKENS",
          payload: {
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
            role: res.role,
            permissions,
            verified: res.verified,
          },
        })

        if (!res.role?.permissions || !res.role?.permissions?.length) {
          dispatch(showAlert("Bu sahifa uchun sizda ruxsatlar mavjud emas!"))
          return
        }
        setTimeout(() => {
          window.location.replace(`${process.env.REACT_APP_FULL_DOMAIN}/#/home/dashboard`)
          setTimeout(function () {
            axios.get("/staff-by-token").then((res) => {
              axios.put("/staff-last-login/" + String(res?.id))
            })
          }, 10000)
        }, 1000)
      })
      .catch((err) => {
        dispatch(showAlert(err?.data?.message))
      })
    //eslint-disable-next-line
  }, [url])

  const onFormSubmit = (e) => {
    e.preventDefault()
    if (!login.trim() || !password.trim()) return setError(true)

    setLoader(true)

    axios
      .post("/login", { login, password })
      .then((res) => {
        localStorage.removeItem("return-admin")
        if (!res.role?.permissions || !res.role?.permissions?.length) {
          dispatch(showAlert("Bu sahifa uchun sizda ruxsatlar mavjud emas!"))
          return
        }
        const permissions = res.role.permissions.map((permission) => permission.name)

        dispatch({
          type: SET_AUTH_TOKENS,
          payload: {
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
            login,
            permissions,
            verified: res.verified,
          },
        })
        history.push("/home/dashboard")

        setTimeout(function () {
          axios.get("/staff-by-token").then((res) => {
            axios.put("/staff-last-login/" + String(res?.id))
          })
        }, 10000)
      })
      .catch((e) => dispatch(showAlert(e?.data?.message ?? "Login yoki parol noto'gri terilgan")))
      .finally(() => setLoader(false))
  }

  const validation = (value) => {
    if (!error || value) return ""
    return "error"
  }

  return (
    <div className="h-screen md:flex font-body login-form">
      <AlertComponent />
      <div className="md:w-6/12 w-full md:h-screen md:p-0 py-4 flex flex-col gap-5 justify-center items-center bg-gradient-to-t from-blue-50 via-white-100 via-white-100 to-white">
        <img src={KadastrLogo} alt="logo" className="md:w-1/2" />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="login-company-name text-xl ">YERELEKTRON AAT</div>
        </div>
      </div>
      <div className="md:w-6/12 w-full md:h-screen md:px-0 px-4 h-5/6 bg-background justify-center items-center flex flex-col shadow ">
        <div className="md:w-3/4 w-full rounded-2xl shadow-lg bg-white h-6/12 p-3">
          <div className="text-3xl font-semibold p-4">{t("signin")}</div>

          <form onSubmit={onFormSubmit}>
            {codeInputVisible && !loginVisible && (
              <div className="flex flex-col p-6 font-semibold space-y-6">
                <input
                  className="border-2 rounded-sm p-1 bg-white"
                  placeholder="Code"
                  onChange={(e) => {
                    if (e.target.value === SEQ_LOGIN) {
                      setLoginVisible(true)
                    }
                  }}
                />
              </div>
            )}
            {loginVisible && (
              <>
                <div className="flex flex-col p-6 font-semibold space-y-6">
                  <div className={`flex flex-col space-y-2 ${validation(login)}`}>
                    <label>{t("username")}</label>
                    <span className="flex items-center space-x-2 p-3 bg-background_2 rounded-lg form-item">
                      <span>
                        <AccountCircleIcon style={{ color: "#6E8BB7" }} />
                      </span>
                      <input
                        placeholder={t("login")}
                        type="text"
                        spellCheck="false"
                        id="login"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                      ></input>
                    </span>
                  </div>
                  <div className={`flex flex-col space-y-2 ${validation(password)}`}>
                    <label>{t("password")}</label>
                    <span className="items-center space-x-2 p-3 bg-background_2 rounded-lg flex form-item">
                      <span>
                        <LockIcon style={{ color: "#6E8BB7" }} />
                      </span>
                      <input
                        type={passworType ? "password" : "text"}
                        placeholder={t("enter.password")}
                        spellCheck="false"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                      <span className="cursor-pointer" onClick={() => setPassordType(!passworType)}>
                        {passworType ? (
                          <Visibility style={{ color: "#6E8BB7" }} />
                        ) : (
                          <VisibilityOff style={{ color: "#6E8BB7" }} />
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="px-6 py-3">
              {loginVisible && (
                <Button
                  type="submit"
                  className="w-full mb-2 flex justify-center align-center"
                  color="primary"
                  shape="filled"
                  size="large"
                  loading={loader}
                >
                  {t("enter")}
                </Button>
              )}
              <Button
                type="button"
                className="w-full flex justify-center align-center"
                style={{ backgroundColor: "#9C51E0" }}
                shape="filled"
                size="large"
                onClick={() => handleLoginRedirect(() => history.push("/home/dashboard"))}
                // loading={loader}
              >
                {t("OneID bilan kirish")}
              </Button>
            </div>
          </form>
        </div>

        {/* <a href="https://t.me/+g5GBYqIJ3hI1MjAy"> */}
        <div className="rounded h-21 md:w-3/4 w-full p-5 bg-white mt-6 flex justify-between items-center shadow-lg">
          <div className="flex space-x-2 items-center">
            <TelegramIcon />
            <span>{t("support.service")}</span>
          </div>
        </div>
        {/* </a> */}
      </div>
    </div>
  )
}
