import { CLEAR_ON_SIGNOUT, SET_USER_DATA } from "../constants"

const INITIAL_STATE = {
  userData: null,
}

export default function userReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      }
    case CLEAR_ON_SIGNOUT:
      return INITIAL_STATE
    default:
      return state
  }
}

export const setUserData = (data) => ({ type: SET_USER_DATA, payload: data })
