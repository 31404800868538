import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"
import authReducer from "./authReducer"
import systemReducer from "./systemReducer"
import basicReducer from "./basicReducer"
import { persistReducer } from "redux-persist"
import relationReducer from "./relationReducer"
import formModalReducer from "./formModalReducer"
import alertReducer from "./alertReducer"
import stepReducer from "./stepReducer"
import headTailReducer from "./headTailReducer"
import parallelReducer from "./parallelReducer"
import userReducer from "./userReducer"
import applicationFormReducer from "./applicationFormReducer"
import filterReducer from "./filterReducer"
import fileNameIdReducer from "./fileNameIdReducer"
import areaGroupReducer from "./areaGroupReducer"
import statusSaveReducer from "./statusSaveReducer"

const relationPersistConfig = {
  key: "relations",
  storage,
  whitelist: ["relations"],
}
const stepPersistConfig = {
  key: "step",
  storage,
  whitelist: ["steps"],
}
const parallelPersistConfig = {
  key: "parallel",
  storage,
  whitelist: ["parallelGateways"],
}
const headtailPersistConfig = {
  key: "headTail",
  storage,
}

const authPersistConfig = {
  key: "auth",
  storage,
}
const applicationFormPersistConfig = {
  key: "applicationForm",
  storage,
}
const filterPersistConfig = {
  key: "filter",
  storage,
}

const rootReducer = combineReducers({
  system: systemReducer,
  basics: basicReducer,
  alert: alertReducer,
  user: userReducer,
  relations: persistReducer(relationPersistConfig, relationReducer),
  steps: persistReducer(stepPersistConfig, stepReducer),
  parallel: persistReducer(parallelPersistConfig, parallelReducer),
  headTail: persistReducer(headtailPersistConfig, headTailReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  applicationForm: persistReducer(
    applicationFormPersistConfig,
    applicationFormReducer
  ),
  filters: persistReducer(filterPersistConfig, filterReducer),
  modal: formModalReducer,
  fileNameId: fileNameIdReducer,
  areaGroup: areaGroupReducer,
  statusSave: statusSaveReducer,
})

export default rootReducer
