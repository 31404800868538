import { File_NAME_ID } from "../constants"
import { createObjectID } from "mongo-object-reader"

const INITIAL_STATE = {
  fileNameId: createObjectID(),
}

export default function fileNameIdReducer(
  state = INITIAL_STATE,
  { payload, type }
) {
  switch (type) {
    case File_NAME_ID:
      return {
        ...state,
        fileNameId: payload,
      }
    default:
      return state
  }
}
export const fileNameIdAction = (payload) => ({
  type: File_NAME_ID,
  payload,
})
