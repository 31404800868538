import moment from "moment"
import axios from "../../utils/axios"
import { setUserData } from "../reducers/userReducer"

export const fetchUserData = () => {
  return (dispatch) => {
    axios.get('/staff-by-token').then((res) => {
      dispatch(setUserData({
        ...res,
        status: res.role.status,
        passport_issue_date: moment(res.passport_issue_date).format("YYYY-MM-DD")
      }))
    })
  }
}
