import React, { useEffect, useState } from "react"
import { Collapse } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import axios from "../../utils/axios"
import Observeble from "../../utils/Observeble"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

export const observeble = new Observeble()

const targetKey = "@"

const ChildBlock = ({ element, isVisible }) => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [actionLogin, setActionLogin] = useState(false)
  const [actionIsLogin, setActionIsLogin] = useState(false)
  const history = useHistory()

  const userRoleId = useSelector((state) => state.user?.userData)
  //62f493ea370a34ced8503138

  const getData = () => {
    axios.get("global-entity-types").then((res) => {
      setData(res)
    })
  }

  function downHandler({ key }) {
    if (key === targetKey) {
      setActionLogin(true)
      setTimeout(() => {
        setActionLogin(false)
      }, 5000)
    }
  }

  const upHandler = ({ key }) => {}

  const handeChangeNavbar = (e) => {
    if (e.target.value === "001") setActionIsLogin(true)
  }

  const handleLink = (path) => {
    history.push(path)
    setActionLogin(false)
  }

  useEffect(() => {
    getData()

    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)

    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [])

  return (
    <Collapse
      in={isVisible}
      timeout={{
        enter: 300,
        exit: 200,
      }}
    >
      <div className="child-block">
        {element.title === "applications"
          ? data?.map((d, index) =>
              d.active ||
              userRoleId?.role?.id === "61134a4473bf6fe15aaef55c" ||
              userRoleId?.id === "62f493ea370a34ced8503138" ? (
                <NavLink
                  key={d.id + index + ""}
                  to={`/home/application/${d.code}/${d.diagram_id}?discluded_type=${d.code}`}
                  className="nav-element"
                >
                  <div className="label">{t(d?.name)}</div>
                </NavLink>
              ) : (
                <div key={index}></div>
              )
            )
          : element.children.map((child, i) =>
              child.id !== "the.logic.of.the.discussion.of.applications" ? (
                <NavLink
                  key={child.id + i + "1"}
                  to={child?.path}
                  className="nav-element"
                >
                  <div className="label">{t(child?.title)}</div>
                </NavLink>
              ) : actionLogin ? (
                actionIsLogin ? (
                  <div
                    onClick={() => handleLink(child?.path)}
                    key={child.id + i + "0"}
                    className="nav-element"
                  >
                    <div className="label">{t(child?.title)}</div>
                  </div>
                ) : (
                  <div key={child.id} className="p-2">
                    <input
                      className=" border-2 rounded-sm p-1 bg-white"
                      placeholder="Code"
                      onChange={handeChangeNavbar}
                    />
                  </div>
                )
              ) : (
                <div key={i}></div>
              )
            )}
      </div>
    </Collapse>
  )
}

export default React.memo(ChildBlock)
