import { useState } from "react"
import { Alert } from "@material-ui/lab"
import { Collapse } from "@material-ui/core"
const AlertElement = ({ title, type }) => {
  const [open, setOpen] = useState(true)
  return (
    <div>
      <Collapse in={open}>
        <Alert
          onClose={() => setOpen(false)}
          severity={type}
          style={{ padding: "15px 30px", fontSize: 20, alignItems: "center" }}
          className="shake-animation mb-3"
        >
          {title}
        </Alert>
      </Collapse>
    </div>
  )
}

export default AlertElement
