import {
  AccountBalance,
  AccountBalanceWallet,
  Assessment,
  AttachMoney,
  Dashboard,
  Edit,
  Group,
  Map,
  School,
  Room,
  Settings,
  Work,
} from "@material-ui/icons"
import LiveHelpIcon from "@material-ui/icons/LiveHelp"
import { Payment } from "@material-ui/icons"

const routes = [
  {
    id: "dashboard",
    title: "dashboard",
    path: "/home/dashboard",
    isActive: false,
    icon: Dashboard,
    permission: "dashboard",
  },
  {
    id: "new-offers",
    title: "new.offers",
    path: "/home/new-offers",
    isActive: false,
    icon: Work,
    permission: "new_offers",
  },
  {
    id: "applications",
    title: "applications",
    path: "/home/application",
    isActive: true,
    icon: AccountBalance,
    children: [
      {
        id: "application.title.1",
        title: "application.title.1",
        path: "/home/application/1",
        isChild: true,
        permission: "application",
      },
      {
        id: "application.title.2",
        title: "application.title.2",
        path: "/home/application/2",
        isChild: true,
        permission: "application",
      },
      {
        id: "application.title.3",
        title: "application.title.3",
        path: "/home/application/3",
        isChild: true,
        permission: "application",
      },
    ],
  },

  {
    id: "open-card",
    title: "open.card",
    path: "/home/open-map",
    isActive: false,
    icon: Map,
    permission: "open_map",
  },
  
  {
    id: "content",
    title: "content",
    path: "/home/content",
    isActive: false,
    icon: Edit,
    children: [
      {
        id: "news",
        title: "news",
        path: "/home/content/news",
        isChild: true,
        permission: "content",
      },
      {
        id: "handbooks",
        title: "handbook",
        path: "/home/content/handbook",
        isChild: true,
        permission: "content",
      },
      {
        id: "docs",
        title: "normative.docs",
        path: "/home/content/docs",
        isChild: true,
        permission: "content",
      },
    ],
  },
  {
    id: "users",
    title: "users",
    path: "/home/users",
    isActive: false,
    icon: Group,
    children: [
      {
        id: "organization.users",
        title: "organization.users",
        path: "/home/users/premises",
        isChild: true,
        permission: "users_premises",
      },
      {
        id: "applicant",
        title: "applicants",
        path: "/home/users/applicant",
        isChild: true,
        permission: "users_applicant",
      },
    ],
  },
  {
    id: "reports",
    title: "reports",
    path: "/home/reports",
    isActive: false,
    icon: Assessment,
    permission: "dashboard",
  },
  {
    id: "tax.tax",
    title: "tax",
    path: "/home/taxlist/tax",
    icon: AttachMoney,
    isActive: false,
    permission: "tax_rate",
  },
  {
    id: "tax.district",
    title: "tax.district",
    path: "/home/taxlist/district",
    icon: AccountBalanceWallet,
    isActive: false,
    permission: "tax_district",
  },

  {
    id: "settings",
    title: "settings",
    path: "/home/settings",
    isActive: true,
    icon: Settings,
    children: [
      {
        id: "system.settings",
        title: "system.settings",
        path: "/home/settings/system-settings",
        isChild: true,
        permission: "settings_permission_system",
      },
      // {
      //     id: 'announcement',
      //     title: 'announcement',
      //     path: '/home/settings/announcement',
      //     isChild: true,
      //     permission: 'settings_permission_system'
      // },
      {
        id: "districts",
        title: "districts",
        path: "/home/settings/districts",
        isChild: true,
        permission: "settings_city",
      },
      {
        id: "district",
        title: "Mahallalar",
        path: "/home/settings/district",
        isChild: true,
        permission: "settings_city",
      },
      {
        id: "organizations",
        title: "organizations",
        path: "/home/settings/organizations",
        isChild: true,
        permission: "settings_organization",
      },
      {
        id: "governor",
        title: "governor.helper",
        path: "/home/settings/governor",
        isChild: true,
        permission: "settings_governor",
      },
      {
        id: "geoServiceCabinet",
        title: "geoServiceCabinet",
        path: "/home/settings/geo-service-cabinet",
        isChild: true,
        permission: "settings_geo_service_cabinet",
      },
      {
        id: "roles",
        title: "roles",
        path: "/home/settings/roles",
        isChild: true,
        permission: "settings_roles",
      },
      // {
      //   id: 'permission',
      //   title: 'permission',
      //   path: '/home/settings/permission',
      //   isChild: true,
      //   permission: 'settings_permission'
      // },
      {
        id: "application.statuses",
        title: "application.statuses",
        path: "/home/settings/status",
        isChild: true,
        permission: "settings_status",
      },
      {
        id: "application.fields",
        title: "application.fields",
        path: "/home/settings/property",
        isChild: true,
        permission: "settings_property",
      },
      {
        id: "application.field.groups",
        title: "application.field.groups",
        path: "/home/settings/group-property",
        isChild: true,
        permission: "settings_group_property",
      },
      {
        id: "types.of.construction.objects",
        title: "types.of.construction.objects",
        path: "/home/settings/construction-object",
        isChild: true,
        permission: "contruction_object",
      },
      {
        // /settings/auctions
        id: "closed_auction",
        title: "Yopiq auksionlar",
        path: "/home/settings/auctions",
        isChild: true,
        permission: "closed_auction",
      },
      {
        id: "entity.types",
        title: "entity.types",
        path: "/home/settings/entity-type",
        isChild: true,
        permission: "entity_type",
      },
      {
        id: "the.logic.of.the.discussion.of.applications",
        title: "the.logic.of.the.discussion.of.applications",
        path: "/home/settings/diagram-list",
        isChild: true,
        permission: "settings_permission_diagram",
      },
    ],
  },
  {
    id: 'reestrMOdul',
    title: 'Reestr',
    path: '/home/reestrModul',
    isActive: true,
    icon: Room,
    permission: "re_entry_sold",
    children: [
      {
        id: "reestr",
        title: "reestr",
        path: "/home/reestr",
        isChild: true,
        permission: "re_entry_sold",
      },
      {
        id: "makeOffer",
        title: "makeOffer",
        path: "/home/makeOffer",
        isChild: true,
        permission: "re_entry_sold",
      } ]
  },  
  {
    id: "tutorial",
    title: "handbook",
    path: "/home/tutorial",
    isActive: false,
    icon: School,
    permission: "dashboard",
  },
  {
    id: "faqs",
    title: "Savollar/Javoblar",
    path: "/home/faqs",
    isActive: false,
    icon: LiveHelpIcon,
    permission: "FAQ",
  },
  {
    id: "pricing",
    title: "Narx ma'lumotlari",
    path: "/home/pricing",
    isActive: false,
    icon: Payment,
    permission: "davaktiv_g_value",
  },
]

export default routes
