import {
  AREA_PAGINATION,
  AREA_SELECTEDTAB,
  AREA_ALL_VALUES,
} from "../constants"

const INITIAL_STATE = {
  currentPage: 1,
  selectedTab: 0,
}

export default function basicReducer(state = INITIAL_STATE, { payload, type }) {
  switch (type) {
    case AREA_PAGINATION:
      return {
        ...state,
        currentPage: payload,
      }
    case AREA_SELECTEDTAB:
      return {
        ...state,
        selectedTab: payload,
      }
    case AREA_ALL_VALUES:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}
