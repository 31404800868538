import { formModalActionTypes } from "../actions/formModalActions/formModalActions"
import { CLEAR_ON_SIGNOUT } from "../constants"

const initialModalState = {
  open: false,
  source: null,
  currentElement: null,
  type: null,
}

const actionReducer = (state = initialModalState, action) => {
  const { payload } = action
  switch (action.type) {
    case formModalActionTypes.OPEN_MODAL:
      return {
        ...state,
        open: true,
        source: payload,
        currentElement: null,
        type: payload.type,
      }
    case formModalActionTypes.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        source: null,
        currentElement: null,
        type: null,
      }
    case formModalActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        open: !state.open,
      }
    case formModalActionTypes.EDIT_STEP:
      return {
        ...state,
        open: true,
        currentElement: payload,
        type: payload.type,
      }
    case CLEAR_ON_SIGNOUT:
      return initialModalState
    default:
      return state
  }
}

export default actionReducer
