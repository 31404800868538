import {
  SET_FORM_VALUES,
  SET_STATE_FIELD_VALUE,
  CLEAR_FORM_VALUES,
  CLEAR_ON_SIGNOUT,
} from "../constants"

const INITIAL_STATE = {
  formValues: {},
  stateValues: {},
}

export default function applicationFormReducer(
  state = INITIAL_STATE,
  { type, payload }
) {
  switch (type) {
    case SET_FORM_VALUES:
      return {
        ...state,
        ...state.formValues,
        formValues: payload,
      }

    case SET_STATE_FIELD_VALUE:
      return {
        ...state,
        stateValues: {
          ...state.stateValues,
          [payload.field]: payload.value,
        },
      }

    case CLEAR_FORM_VALUES:
      return INITIAL_STATE

    case CLEAR_ON_SIGNOUT:
        return INITIAL_STATE
        
    default:
      return state
  }
}

export const setApplicationStateValue = (field, value) => ({
  type: SET_STATE_FIELD_VALUE,
  payload: { field, value },
})
export const setApplicationFormValues = (data) => ({
  type: SET_FORM_VALUES,
  payload: data,
})
export const clearApplicationFormValue = () => ({ type: CLEAR_FORM_VALUES })
