import "./style.scss"
import { brandLogo } from "../../assets/icons/icons"
import MenuOpenIcon from "@material-ui/icons/MenuOpen"
import { useState, useEffect, useMemo } from "react"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import elements from "./elements"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import ChildBlock from "./ChildBlock"
import { useSelector } from "react-redux"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import LogoutModal from "../Sidebar/Modal"
import { useDispatch } from "react-redux"
import { CLEAR_ON_SIGNOUT } from "../../redux/constants"
import axios from "../../utils/axios"
import SidebarProfile from "./SidebarProfile"

const Sidebar = () => {
  const { t } = useTranslation()
  const [openedBlock, setOpenedBlock] = useState(null)
  const [rightSideVisible, setRightSideVisible] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [unseenDrafts, setUnseenDrafts] = useState(0)

  const userData = useSelector((state) => state?.user?.userData)
  // const login = useSelector((state) => state.auth.login)
  // const userLogin = useSelector((state) => state?.user?.userData?.login)
  const permissions = useSelector((state) => state.auth.permissions)
  const roleId = useSelector((state) => state?.user?.userData?.role?.id)
  const dispatch = useDispatch()

  const parentClickHandler = (element) => {
    if (element.children) {
      switchChildBlockHandler(element.id)
      if (!rightSideVisible) setRightSideVisible(true)
    } else setOpenedBlock(null)
  }

  const switchChildBlockHandler = (id) => {
    setOpenedBlock((prev) => (prev === id ? null : id))
  }

  useEffect(() => {
    if (!rightSideVisible) setOpenedBlock(null)
  }, [rightSideVisible])

  const logoutHandler = () => {
    setIsModalOpen(true)
  }

  const computedMenuElements = useMemo(() => {
    const result = []
    elements.forEach((element) => {
      if (!element.children) {
        if (permissions.includes(element.permission)) result.push(element)
      } else {
        const childElements = []
        element.children.forEach((childElement) => {
          if (permissions.includes(childElement.permission)) childElements.push(childElement)
        })
        if (childElements.length)
          result.push({
            ...element,
            children: childElements,
          })
      }
    })
    return result
  }, [permissions])

  useEffect(() => {
    if (
      [
        "6136faefc55c3d063b28a124", // Qurilish tuman
        "6136faefc55c3d063b28a11f", // Palata tuman
      ].includes(roleId)
    ) {
      axios
        .get("/entity-draft-seen")
        .then((res) => {
          setUnseenDrafts(res?.count)
        })
        .catch(console.error)
    }
  }, [roleId])

  return (
    <>
      <div
        onClick={() => setRightSideVisible((prev) => !prev)}
        className={`lg:hidden fixed top-0 right-0 h-screen z-50 transition ${!rightSideVisible ? "hidden" : ""}`}
        style={{
          width: "calc(100vw - 280px)",
          backgroundColor: "rgba(0,0,0,0.2)",
        }}
      ></div>
      <div className={`Sidebar ${!rightSideVisible ? "close" : ""}`}>
        <LogoutModal
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
          logout={() => dispatch({ type: CLEAR_ON_SIGNOUT })}
        />
        <div className="header">
          <div className="brand">
            <div className="brand-logo" onClick={() => setRightSideVisible((prev) => !prev)}>
              {brandLogo}
            </div>
            <div className="brand-name">YERELEKTRON AAT</div>
          </div>

          <div className="cloes-btn" onClick={() => setRightSideVisible((prev) => !prev)}>
            <MenuOpenIcon />
          </div>
        </div>
        <div className="nav-block" style={{ height: `calc(100vh - ${72}px)` }}>
          <div className="menu-element">
            <hr />
            <div>
              <SidebarProfile userData={userData} rightSideVisible={rightSideVisible} />
            </div>
            <hr className="pb-2" />
            {computedMenuElements?.map((element) => {
              if (element?.path === "/home/dashboard" && userData?.role?.id === "6136faeec55c3d063b28a118") {
                // rever__code
                return <></>
              }

              if ("reports" === element.id) {
                return [
                  "611cf2856ec6231ae0c647a4", // geoinfocom
                  "61027e35772d476a220673e6", // davlat kadastrlari palatasi
                  "61f26b37c105e1d57c2bd075", // davlat aktivlarini boshqarish agentligi
                  "64098ab7aafdd17e3e9048d8", // kadastr agentligi
                ].includes(userData?.organization?.id) &&
                  [
                    "61134a4473bf6fe15aaef55c", // geoinfocom
                    "61541b25901daa0e51cc3f44", // davlat kadastrlari palatasi
                    "6228383724cc2cf190227be3", // davlat aktivlarini boshqarish agentligi
                    "64098c48aafdd17e3e904913", // kadastr agentligi
                  ].includes(userData?.role?.id) ? (
                  <div key={element.id} className="parent-block">
                    <NavLink
                      to={element.path}
                      exact={false}
                      activeClassName={element.children ? "active-with-child" : "active"}
                      className="nav-element"
                      onClick={(e) => {
                        if (element.children) e.preventDefault()
                        parentClickHandler(element)
                      }}
                    >
                      <div className="icon">
                        <element.icon />
                      </div>
                      <div className="label">{t(element.title)}</div>
                      {element.id === "new-offers" && unseenDrafts > 0 && (
                        <span className="bg-red-500 text-xs text-white p-1 mr-1 rounded-full">{unseenDrafts}</span>
                      )}
                      {element.children && (
                        <div className={`arrow-icon ${openedBlock === element.id ? "open" : ""}`}>
                          <ExpandMoreIcon />
                        </div>
                      )}
                    </NavLink>

                    {element.children && <ChildBlock element={element} isVisible={openedBlock === element.id} />}
                  </div>
                ) : null
              }
              return (
                <div key={element.id} className="parent-block">
                  <NavLink
                    to={element.path}
                    exact={false}
                    activeClassName={element.children ? "active-with-child" : "active"}
                    className="nav-element"
                    onClick={(e) => {
                      if (element.children) e.preventDefault()
                      parentClickHandler(element)
                    }}
                  >
                    <div className="icon">
                      <element.icon />
                    </div>
                    <div className="label">{t(element.title)}</div>
                    {element.id === "new-offers" && unseenDrafts > 0 && (
                      <span className="bg-red-500 text-xs text-white p-1 mr-1 rounded-full">{unseenDrafts}</span>
                    )}
                    {element.children && (
                      <div className={`arrow-icon ${openedBlock === element.id ? "open" : ""}`}>
                        <ExpandMoreIcon />
                      </div>
                    )}
                  </NavLink>

                  {element.children && <ChildBlock element={element} isVisible={openedBlock === element.id} />}
                </div>
              )
            })}
          </div>
          <div className="sidebar-footer">
            {/* <div className="parent-block">
              <NavLink className="nav-element " to="/home/profile" style={{ padding: "10px 0px" }}>
                <div className="profile-avatar">
                  {login ? login[0]?.toUpperCase() : userLogin ? userLogin[0]?.toUpperCase() : null}
                </div>
                <div className="label">Shaxsiy ma'lumotlar</div>
              </NavLink>
            </div> */}
            <div className="parent-block">
              <div className="nav-element" onClick={logoutHandler}>
                <div className="icon">
                  <ExitToAppIcon />
                </div>
                <div className="label">Chiqish</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
